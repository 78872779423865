import React, { useContext } from 'react'
import "./footer.css"
import Social from "../common/socialContact/Social";
import { ThemeContext } from '../../darkModeContext';

export default function Footer() {

    const theme=useContext(ThemeContext);
  //const darkMode = theme.state.darkMode;
  //const darkMode = localStorage.getItem("darkMode");
  const darkMode = JSON.parse(theme.state.darkMode);
  return (
    <>
    <div className="footer-container">
        <div className="footer-container_logo">
          <a href="https://www.ashishpradhan.dev/" className='footer-logo'>ASHISH</a>
          <p className='footer-description' style={{color:darkMode && "black"}}>I'm a web developer. I build things for the web and have a thing for frontend development.</p>
        </div>
        <div className="footer-container_links" style={{color:darkMode && "black"}}>
          <span className='footer-link_header'>Useful Links</span>
           <div className="footer-links">
              <a href='https://www.ashishpradhan.dev/'>Home</a>
              <a href='https://www.ashishpradhan.dev/#about'>About</a>
              <a href='https://www.ashishpradhan.dev/#projects'>Projects</a>
              <a href='https://www.ashishpradhan.dev/#contact'>Contact</a>
           </div>

        </div>
        <div className="footer-container_social" >
          <span className='footer-social_header' style={{color:darkMode && "black"}}>Social</span>
          <Social/>
          <span>Email:ashish.pradhan0696@gmail.com</span>
        </div>
    </div>
    <hr className='footer-hr'></hr>
    <div className="footer-copyright" style={{color:darkMode && "black"}}>
        <i className="fa fa-copyright" aria-hidden="true"></i>
        Copyright 2022.Made by Ashish
    </div>
    
    
    </>
  )
}
