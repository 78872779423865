import React from 'react'
import Body from '../../components/body/Body'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import "./home.css";

export default function Home() {
  return (
    <>
    <div className="home-container" id="home">
      <Header/>
      <Body />
      <Footer/>
    </div>

    </>
  )
}
