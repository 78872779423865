import { createContext, useReducer } from "react";

export const ThemeContext = createContext();

// localStorage.setItem("darkMode",false);

// const INITIAL_STATE = { darkMode: false };
// console.log("intial");
const INITIAL_STATE = { darkMode: localStorage.getItem("darkMode") === null ? false : JSON.parse(localStorage.getItem("darkMode")) };
console.log("darkmodecontext.js render:", INITIAL_STATE.darkMode);

const themeReducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE":
            localStorage.setItem("darkMode", state.darkMode === null || state.darkMode === false ? true : false);
            // return { darkMode: !state.darkMode };
            // return { darkMode: state.darkMode === null || state.darkMode === false ? true : false };
            return { darkMode: JSON.parse(localStorage.getItem("darkMode")) }
        default:
            return state;
    }
}

export const ThemeProvider = (props) => {
    const [state, dispatch] = useReducer(themeReducer, INITIAL_STATE);

    return (
        <ThemeContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ThemeContext.Provider>
    )
}