import React, { useContext } from 'react'
import "./mobile.css";
import resume from "../../../assets/Resume_Ashish.pdf";
import { ThemeContext } from '../../../darkModeContext';

export default function Mobile({isOpen, setIsOpen}) {

  const theme=useContext(ThemeContext);
   // const darkMode = theme.state.darkMode;
    //const darkMode = localStorage.getItem("darkMode");
    const darkMode = JSON.parse(theme.state.darkMode);

  return (
    <>
     <div className='mobile-container'>
        <div onClick={()=>setIsOpen(!isOpen)}>
           <i className="fa fa-times close-icon" aria-hidden="true"  style={{color:darkMode && "black"}}></i>
        </div>
        <div className="mobile-container_options">
            <div className="mobile-container_option">
                  <a href='https://www.ashishpradhan.dev/' rel="noreferrer">
                    <i className="fa fa-briefcase option-icon" aria-hidden="true"></i> Home
                  </a>
            </div>
            <div className="mobile-container_option">
                <a href='https://www.ashishpradhan.dev/#about' rel="noreferrer">
                  <i className="fa fa-laptop option-icon" aria-hidden="true"></i>About
                </a>
            </div>
            <div className="mobile-container_option">
                <a href='https://www.ashishpradhan.dev/#projects' rel="noreferrer">
                  <i className="fa fa-info option-icon" aria-hidden="true"></i>Projects
                </a>
            </div>
            <div className="mobile-container_option">
                <a href='https://www.ashishpradhan.dev/#contact' rel="noreferrer">
                  <i className="fa fa-user-o option-icon" aria-hidden="true"></i>Contact
                </a>
            </div>
            <div className="mobile-container_option">
                <a href={resume} target="_blank" rel="noreferrer" >
                  <i className="fa fa-file-pdf-o option-icon" aria-hidden="true"></i>Resume
                </a>
            </div>
        </div>
       
    </div>
    </>
  )
}
