import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "./caseStudy.css";
import { caseStudy } from '../../data/CaseStudies';
import Header from '../../components/header/Header';
import parse from "html-react-parser";
import Footer from '../../components/footer/Footer';
import { ThemeContext } from '../../darkModeContext';


export default function CaseStudy() {
   const navigate=useNavigate(); 
   const location=useLocation();
   const currentId=location.pathname.split("/")[2]; 
  
   const index=caseStudy.filter((ele)=>
        ele.id===currentId    
    )

   const project=index[0];
  // console.log(data);

  const theme=useContext(ThemeContext);
   // const darkMode = theme.state.darkMode;
    //const darkMode = localStorage.getItem("darkMode");
    const darkMode = JSON.parse(theme.state.darkMode);

  return (
    <>
   
<div className="casestudy-container">
            <Header/>
                <div className="caseStudy-header" >
                        <span className="caseStudy-header_title">{project.title}</span>
                        <img src={project.photoUrl} className="caseStudy-header_image"/>
                        <div className="caseStudy-header_buttons">
                            <a href={project.github} title="Github" target="_blank" style={{color: darkMode && "whitesmoke"}}>
                                <i className="fa fa-github-square github-icon" aria-hidden="true"></i>
                            </a>
                            <a href={project.liveLink} title="Live link" target="_blank" style={{color: darkMode && "whitesmoke"}}> 
                                <i className="fa fa-external-link-square live-icon" aria-hidden="true"></i>
                            </a>
                        </div>                       
                </div>
                <div className="caseStudy-details"  style={{color: darkMode && "whitesmoke"}}>
                        <div className="caseStudy-details_intro"  style={{backgroundColor:darkMode && "#272727"}}>
                            <label className="caseStudy-details-intro_header">About this project</label>
                            <p className="caseStudy-details-intro_description">{project.description}</p>
                            {project.features.map((feature)=>(
                                <p className="caseStudy-details-intro_features">
                                    {/* <ul> */}
                                        <i class="fa fa-check feature-icon" aria-hidden="true"></i> {parse(feature)}
                                    {/* </ul> */}
                                </p>
                            ))}
                            
                        </div>
                        {project.problem && 
                        <div className="caseStudy-details_problem" style={{backgroundColor:darkMode && "#272727"}}>
                            <label className="caseStudy-details-problem_header">PROBLEM</label>
                            <p className="caseStudy-details-problem_description">
                               {parse(project.problem)}
                            </p>
                            {/* <p className="caseStudy-details-problem_description" dangerouslySetInnerHTML={ {__html: project.problem}}/> */}
                        </div>
                        }
                        {project.actionPlan && 
                        <div className="caseStudy-details_action" style={{backgroundColor:darkMode && "#272727"}}>
                            <label className="caseStudy-details-action_header">ACTION PLAN</label>
                            <p className="caseStudy-details-action_description">{parse(project.actionPlan)}</p>
                        </div>
                        }
                        {project.challenges.length>0 && 
                        <div className="caseStudy-details_challenges" style={{backgroundColor:darkMode && "#272727"}}>
                            <label className="caseStudy-details-challenges_header">CHALLENGES/LESSONS LEARNT</label>
                            {project.challenges.map((challenge)=>(
                                <p className="caseStudy-details-challenges_description">
                                    {/* <ul> */}
                                        <i class="fa fa-dot-circle-o challenge-icon" aria-hidden="true"></i> {parse(challenge)}
                                    {/* </ul>     */}
                                </p>  
                            ))}
                            
                        </div>
                        }
                        
                        {project.lookingAhead.length>0 && 
                        <div className="caseStudy-details_ahead" style={{backgroundColor:darkMode && "#272727"}}>
                            <label className="caseStudy-details-ahead_header">LOOKING AHEAD</label>
                            {project.lookingAhead.map((ahead)=>(
                                <p className="caseStudy-details-ahead_description">
                                    {/* <ul> */}
                                        <i class="fa fa-thumb-tack ahead-icon" aria-hidden="true"></i> {parse(ahead)}
                                    {/* </ul> */}
                                </p>
                            ))}
                        </div>
                        }
                        
                        
                </div>
                <div className="caseStudy-buttons">
                        {project.issue &&
                        <span>
                          {parse(project.issue)} <a style={{color: darkMode && "whitesmoke"}} href="https://github.com/ashishpradhan0696/leanced.com-production/issues" target="_blank"> here </a> </span>
                    }
                        <button onClick={()=>navigate(-1)}>Go back</button>  
                </div>
               <Footer/> 
                
        </div>
 
        
    </>
  )
}
