import React, { useContext } from 'react'
import "./web.css"
import resume from "../../../assets/Resume_Ashish.pdf";
import { ThemeContext } from '../../../darkModeContext';

export default function Web() {

  const theme=useContext(ThemeContext);
   // const darkMode = theme.state.darkMode;
    //const darkMode = localStorage.getItem("darkMode");
    const darkMode = JSON.parse(theme.state.darkMode);

  return (
    <>
    <div className="web-container">
       <div className="web-container_option">
          <a href='https://www.ashishpradhan.dev/' rel="noreferrer" style={{color:darkMode && "whitesmoke"}}>
            <i className="fa fa-home option-icon" aria-hidden="true"></i>Home
          </a>
       </div>
        <div className="web-container_option">
          <a href='https://www.ashishpradhan.dev/#about' rel="noreferrer" style={{color:darkMode && "whitesmoke"}}>
            <i className="fa fa-briefcase option-icon" aria-hidden="true"></i>About
          </a>
       </div>
        <div className="web-container_option">
          <a href='https://www.ashishpradhan.dev/#projects' rel="noreferrer" style={{color:darkMode && "whitesmoke"}}>
            <i className="fa fa-laptop option-icon" aria-hidden="true"></i>Projects
          </a>
       </div>
        <div className="web-container_option">
          <a href='https://www.ashishpradhan.dev/#contact' rel="noreferrer" style={{color:darkMode && "whitesmoke"}}>
            <i className="fa fa-user option-icon" aria-hidden="true"></i>Contact
          </a>
       </div>
       <div className="web-container_option">
          <a href={resume} target="_blank" rel="noreferrer">
            <i className="fa fa-file-pdf-o option-icon" aria-hidden="true"></i>Resume
          </a>
       </div>
    </div>
    </>
  )
}
