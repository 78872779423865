import React, { useContext } from 'react'
import introImage from "../../../assets/images/intro-image.svg";
import { ThemeContext } from '../../../darkModeContext';
import Social from '../../common/socialContact/Social';
import "./intro.css"

export default function Intro() {

  
    const theme=useContext(ThemeContext);
    //const darkMode = theme.state.darkMode;
    //const darkMode = localStorage.getItem("darkMode");
    const darkMode = JSON.parse(theme.state.darkMode);

  return (
    <>
        <div className="intro-container">
            <div className="intro-container_top">
                    <div className="intro-info">
                        Hello there, I am 
                        <br/> <span className='intro-info_name' style={{backgroundColor: darkMode && "whitesmoke" , borderRadius:darkMode && "4px"}}>Ashish Pradhan</span><br/>
                        I am a Web developer with a passion for front end. Welcome to my corner of the internet. I'm glad you're here!
                        <p className='disclaimer'>Disclaimer: This UI is as <b><a href="https://dictionary.cambridge.org/dictionary/english/retro" target="_blank">retro</a></b> as it gets.</p>
                    </div>
                     <div className="intro-image">
                       <img src={introImage} alt="portfolio" className="image"/>
                      
              
               
              
              
                    </div>
            </div>
           
              <Social/>
        </div>
    </>
  )
}
