import project1Image from "../assets/images/project1.png"
import project2Image from "../assets/images/project2.png"
import project3Image from "../assets/images/project3.png"

export const projects = [
    {
        id: 1,
        title: "Leanced.com",
        description: "Leanced is an online informational web app for visualizing nutritional facts about health supplements",
        stack: ["ReactJS", "Material UI", "ChartJS", "EmailJS", "Firebase"],
        github: "https://github.com/ashishpradhan0696/leanced.com-production",
        liveLink: "https://www.leanced.com/",
        learnMore: "leanced",
        image: project1Image
    },

    {
        id: 2,
        title: "Scout news",
        description: "Scout news is a simple news app to keep you updated with your daily dose of news",
        stack: ["ReactJS", "Bootstrap", "NewsCatcher API"],
        github: "https://github.com/ashishpradhan0696/Scout-News",
        liveLink: "https://scout-news.netlify.app/",
        learnMore: "scoutnews",
        image: project2Image
    },

    {
        id: 3,
        title: "E-commerce app UI",
        description: "I decided to build this simple yet modern and sleek UI for an e-commerce web app",
        stack: ["ReactJS", "Styled components"],
        github: "https://github.com/ashishpradhan0696/ecommApp-UI",
        liveLink: "https://ecomappui-ashish.netlify.app/",
        learnMore: "ecommapp",
        image: project3Image
    }
]