import React from 'react'
import { useContext } from 'react';
import { ThemeContext } from '../../../darkModeContext';
import "./social.css"; 


export default function Social() {

    const theme=useContext(ThemeContext);
    //const darkMode = theme.state.darkMode;
    //const darkMode = localStorage.getItem("darkMode");
    const darkMode = JSON.parse(theme.state.darkMode);


  return (
    <>
        <div className="social-container" style={{backgroundColor: darkMode && "rgb(55, 55, 55)", borderRadius:darkMode && "4px"}} >
                <div className="social-container_item" title='LinkedIn'>
                    <a  href='https://www.linkedin.com/in/ashish-pradhan0696/' target="_blank" style={{color: darkMode && "whitesmoke"}} >
                        <i className="fa fa-linkedin-square social-icon" aria-hidden="true" ></i>
                    </a>
                </div>

                <div className="social-container_item " title='Github'>
                    <a  href='https://github.com/ashishpradhan0696' target="_blank" style={{color: darkMode && "whitesmoke"}} >
                        <i className="fa fa-github-square social-icon" aria-hidden="true" ></i>
                    </a>
                </div>

                <div className="social-container_item" title='Stack overflow'>
                    <a  href='https://stackoverflow.com/users/14672005/ashish-pradhan' target="_blank" style={{color: darkMode && "whitesmoke"}} >
                        <i className="fa fa-stack-overflow social-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div className="social-container_item" title='Email'>
                    <a  href='mailto:ashish.pradhan0696@gmail.com' style={{color: darkMode && "whitesmoke"}} >
                        <i className="fa fa-envelope social-icon" aria-hidden="true"></i>
                    </a>
                </div>
        </div>
    </>
  )
}
