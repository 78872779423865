import { useContext, useEff } from "react";
import { ThemeContext } from "./darkModeContext";
import Home from "./pages/Home/Home";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import CaseStudy from "./pages/CaseStudy/CaseStudy";
import InitialTopScroll from "./components/initialTopScroll/InitialTopScroll";


function App() {
  const theme = useContext(ThemeContext);
  const darkMode = JSON.parse(theme.state.darkMode);

  //const darkMode = localStorage.getItem("darkMode");
  console.log("App.js render : ", darkMode);

  return (
    <Router>
      <InitialTopScroll />
      <div style={{ backgroundColor: darkMode ? "#222" : "whitesmoke", color: darkMode && "white", transitionDuration: "2s" }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/projects/:id" element={<CaseStudy />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
