import React, { useContext } from 'react'
import "./projects.css"
import {projects} from "../../../data/Projects";
import { ThemeContext } from '../../../darkModeContext';
import { useNavigate } from 'react-router-dom';

export default function Projects() {

  const theme=useContext(ThemeContext);
 // const darkMode = theme.state.darkMode;
  //const darkMode = localStorage.getItem("darkMode");
  const darkMode = JSON.parse(theme.state.darkMode);

  const navigate=useNavigate();

  return (
     <>
        <div className="projects-container" id="projects">
            <label className='projects-container_header'>PROJECTS</label>
            <span><hr/></span>

            <div className="projects-container_top">
               <span>Some things I've built</span>
               <hr></hr>  
            </div>
            {projects.map((project)=>(
              
                <div className="projects-container_bottom">
                      <div className="projects-container_bottom-left">  
                            <div className="project-title">{project.title}</div>
                            <div className="project-description" style={{color:darkMode && "black" , backgroundColor:darkMode && "whitesmoke"}}>{project.description}</div>
                            <div className='project-stack_header'>Built with</div>
                            <div className="project-stack" style={{color:darkMode && "black"}}>
                              {project.stack.map((stackItem)=>(
                                 <span className='project-stack_item'>{stackItem}</span>
                              ))}
                            </div>
                            <div className="project-links">
                              <a className='project-links_item' title="Github" href={project.github} target="_blank" rel='noreferrer' style={{color:darkMode && "whitesmoke"}}>
                                <i className="fa fa-github" aria-hidden="true"></i>
                              </a>
                              <a className='project-links_item' title="Live link" href={project.liveLink} target="_blank" rel='noreferrer' style={{color:darkMode && "whitesmoke"}}>
                                <i className="fa fa-external-link-square" aria-hidden="true"></i> 
                              </a>
                              <a className='project-links_item' title="Know more about this project" target="_blank" rel='noreferrer' onClick={()=>navigate(`/projects/${project.learnMore}`)}>
                                <button className='project-links_button'>Learn More</button>  
                              </a>
                            </div>
                      </div>
                      <div className="projects-container_bottom-right">
                         <div className="project-card">
                              <div className="project-card_header">
                                <span className='circle'></span>
                                <span className='circle'></span>
                                <span className='circle'></span>
                              </div>
                              <a href={project.liveLink} target="_blank" rel='noreferrer'>
                                   <img className="project-card_image" src={project.image} alt={project.title}/>
                              </a>   
                         </div>  
                      </div>  
                       
                </div>
                
            ))}
            
           
        </div>
    </>
  )
}
