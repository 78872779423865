import React, { useContext } from 'react'
import { ThemeContext } from '../../darkModeContext';

export default function DarkMode() {

    const theme=useContext(ThemeContext);
    //const darkMode = theme.state.darkMode;
    //const darkMode = localStorage.getItem("darkMode");
    //const darkmode=darkMode;
    const darkMode = JSON.parse(theme.state.darkMode);

    const handleClick=()=>{
        theme.dispatch({type:"TOGGLE"})
    }

  return (
    <>
    <div className="darkMode-container">
       <i className={`${darkMode?"fa fa-sun-o":"fa fa-moon-o"}`} style={{cursor:"pointer" , color: darkMode ? "whitesmoke" : "black" }} aria-hidden="true" onClick={handleClick}></i>
    </div>
    </>
  )
}
