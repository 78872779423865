import React, { useContext, useState } from 'react'
import "./contact.css";
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { ThemeContext } from '../../../darkModeContext';

export default function Contact() {
    const theme=useContext(ThemeContext);
    //const darkMode = theme.state.darkMode;
   // const darkMode = localStorage.getItem("darkMode");
const darkMode = JSON.parse(theme.state.darkMode);
  const [success, setSuccess]=useState(false);

  const form = useRef(); 

    const handleFormSubmit=(e)=>{
        console.log("inside form submit");
            e.preventDefault();
                
            emailjs.sendForm('portfolio1996', 'template_f5m8g5s', form.current, '0fz8OU6ikK6yhYckt')
            .then((result) => {
                e.target.reset();
                
                setSuccess(true);

                setTimeout(() => {
                 setSuccess(false);
                }, 1500);

                // console.log(result.text)
            }, (error) => {
                console.log(error.text);
            });

  };


  return (
     <>
        <div className="contact-container" id="contact">
            <label className='contact-container_header'>CONTACT</label>
            <span><hr/></span>
            <div className="contact-container_info">
                <div className="contact-info_left">
                    <p>Get in touch with me through this form</p> 
                     <h3>OR</h3>
                     <a href="mailto:ashish.pradhan0696@gmail.com" rel='noreferrer' style={{color:darkMode && "whitesmoke"}}>Say Hello<span className='helloButton'>👋</span> </a>

                 </div>
                <div className="contact-info_right">
                    <form className='contact-form' ref={form} onSubmit={handleFormSubmit}>
                      <label className='formLabel'>Name</label>
                      <input type="text" name="user-name" placeholder='Enter your name' className='formInput' required/>
                      <label className='formLabel'>Email</label>
                      <input type="email" name="user-email" placeholder='Enter your email' className='formInput' required/>
                      <label className='formLabel'>Message</label>
                      <textarea rows="10" name="user-message" placeholder='Enter your message' className='formInput' required/>
                      <input type="submit" value="SEND" className="submitButton"/>
                      <span className='successMessage' style={{opacity:success && "1"}}>Message sent<i className="fa fa-check" aria-hidden="true"></i></span>
                      
                    </form>
                </div>
                
            </div>
           
            
        </div>
         
        
    </>
  )
}
