import leanced from "../assets/images/leanced.png"
import scoutnews from "../assets/images/scoutnews.png"
import ecommapp from "../assets/images/ecommapp.png"



export const caseStudy = [
    {
        id: "leanced",
        title: "LEANCED",
        photoUrl: leanced,
        github: "https://github.com/ashishpradhan0696/leanced.com-production",
        liveLink: "https://www.leanced.com",
        description: "Leanced is an online informational platform which brings you vital key nutrition facts related to health supplements in an easy interactive way.",
        features: ["Search for a product from the list",
            "Filter products by brand",
            "Visualize key nutrition facts about the health supplement",
            "Interactive charts allows you to unselect any label/content from the chart and visualize each labels individually by clicking on it",
            "Toggle the charts and the nutritional information between “<b>per serving size view</b>” and “<b>per 100gm serving view</b>” for the respective product",
            "Login using your email or Google authentication",
            "Add product to watchlist to visualize it later",
            "Know more about your supplement in the FAQ section",
            "Suggest a feedback or report an issue from the 'Feedback' section"
        ],
        problem: "<em><b>How many standard apples should a standard human eat to get a standard dose of Vitamin C ? Not easy right ? </b></em> <br> Diet and a good health is an integral part of your life, and so do supplements. Sometimes, we spend too much time searching for the right health supplement, be it a whey protein, fish oil or a multi vitamin, and most of the times end up in a dilemma of choosing the right product.Or sometimes, its just too confusing for a beginner who has just started out his fitness journey to crawl over the internet and spend countless hours watching reviews on his/her way to decide what fits best. ",
        actionPlan: "I planned to make this easier for the people to turn the dilemma into a solution. Leanced lets you choose a product and visualize (only) few of the key nutrition facts in it, and decide for yourself which product fits your needs to be your next health supplement .The goal was to create and implement something that is appealing, interactive and far away from the usual traditional nutrition facts label.So, I decided to put <u>Material UI</u> in use to create few of the components within the user interface and <u>Chart.js</u> open-source library for data visualization.",
        challenges:
            [
                "One of the foremost and biggest challenge was to gather the nutrition facts about the products.Not every brand discloses the breakdown of nutrition facts for their products directly over an e-commerce site, and sometimes it just gets hard to find it over the internet or any e-commerce site. <p><i><small>Endless hours crawling over the internet through various trusted resources and 20+ coffees later, I somehow managed to gather the information, either through searching or by reaching out to the brand directly over a support email</small></i></p>",
                "One of the few technical issues was with the performance of the app <p><i><small>To improve the performance, I used route based code splitting , a concept of React which dramatically helps in improving the performance of your web app</small></i></p>",
                "Since my home page uses a high resolution image as its background, sometimes when the page is opened , the image is still loading. This impacts the user experience and it worsens when we have a very slow internet connection , and didn’t seem good to my liking either <p><i><small>-I implemented progressive asset loading technique to provide the user a glimpse of the image coming up gradually until it loads completely</small></i></p>",
                "Managing the auth and watchlist state across various components was a bit of a task.Currently, the project doesn't use any state management library, however I plan to implement Redux for the state managament in future, as I learn more about it."
            ],
        lookingAhead:
            [
                "The current UI for the website may not be the best, but there’s always room for improvement",
                "Implementing Redux for state management",
                "Adding other categories of health supplements and their nutrition facts and having a separate section for each supplement category"
            ],
        issue: "Want to contribute or have an idea that could be useful? Feel free to open an issue",
    },
    {
        id: "scoutnews",
        title: "SCOUT NEWS",
        photoUrl: scoutnews,
        github: "https://github.com/ashishpradhan0696/Scout-News",
        liveLink: "https://scout-news.netlify.app/",
        description: "Scout news is a simple news app to keep you updated with your daily dose of news. I decided to build this as a way to implement infinite scrolling and working with APIs.",
        features: ["View top headlines across various categories", "View last published date", "View news source", "Toggle between dark and light mode", "Infinite scrolling allows you to fetch news from each category as you scroll down"],
        problem: "",
        actionPlan: "",
        challenges: [],
        lookingAhead: [],
        issue: ""
    },
    {
        id: "ecommapp",
        title: "E-COMM APP",
        photoUrl: ecommapp,
        github: "https://github.com/ashishpradhan0696/ecommApp-UI",
        liveLink: "https://ecomappui-ashish.netlify.app/",
        description: "I decided to build this simple yet modern and sleek UI for an e-commerce web app .I made this as an effort to learn styled components library for React to leverage a mixture of CSS-in-JS. Even though I prefer writing my own old “classic” vanilla CSS as a way to refine my CSS skills every time I build something new(unless situation or need demands otherwise) , but hey, atleast I learned a few things about styled components while building this app.",
        features: ["View cart items", "Register and login page", "View different product categories and products", "View single product details"],
        problem: "",
        actionPlan: "",
        challenges: [],
        lookingAhead: [],
        issue: ""
    }
]