import React from 'react'
import Mobile from './mobile/Mobile'
import Web from './web/Web'
import { useState } from 'react';
import "./header.css";
import DarkMode from '../darkMode/DarkMode';
import signature from "../../assets/signature4.png";
export default function Header() {

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="header-container">
        <div className="header-container_logo">
          <a href="https://www.ashishpradhan.dev/" className='header-logo'>
            <img src={signature} alt="Ashish's signature" className="signature"></img>
          </a>
          <DarkMode />
        </div>
        <div className="header-container_menu" >
          <div className="web-menu" >
            <Web />
          </div>
          <div className="mobile-menu">
            <div onClick={() => setIsOpen(isOpen ? false : true)}>
              {/* <i className={`${isOpen ? "fa fa-times" : "fa fa-bars"}`} aria-hidden="true"></i> */}

              <i className="fa fa-bars open-icon" aria-hidden="true"></i>
            </div>

            {isOpen && <Mobile isOpen={isOpen} setIsOpen={setIsOpen} />}
          </div>
        </div>

      </div>

    </>
  )
}
