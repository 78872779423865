import React from 'react'
import About from './about/About'
import "./body.css"
import Contact from './contact/Contact'
import Intro from './intro/Intro'
import Projects from './projects/Projects'

export default function Body() {
  return (
    <>
      <div className="body-container">
        <section id="intro">
           <Intro/>
        </section>
         <section id="about">
           <About/>
        </section>
         
        <section id="projects">
           <Projects/>
        </section>
         
        <section id="contact">
          <Contact/>
        </section>
          
      </div>
    </>
  )
}
