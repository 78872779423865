import React, { useContext } from 'react'
import { ThemeContext } from '../../../darkModeContext';
import "./about.css"

export default function About() {
  const theme=useContext(ThemeContext);
  //const darkMode = theme.state.darkMode;
const darkMode = JSON.parse(theme.state.darkMode);
  //const darkMode = localStorage.getItem("darkMode");
  
  const skills=["HTML","CSS","JavaScript","ReactJS","Github","Responsive designs", "Single-page web apps(SPA)","ChartJS","Bootstrap","Styled components",
                 "Material UI","Firebase","Express basics","Figma"];

  return (
     <>
        <div className="about-container" id="about">
            <label className='about-container_header'>ABOUT</label>
            <span><hr/></span>
            <div className="about-container_info">
                  <div className="info-left">
                    <label className='info-left_header'>Get to know me</label>
                      A front end developer with a passion for solving problem, exploring and learning new things. My interest in web started back in college days when I stumbled upon a random Youtube HTML tutorial from "EJ Media"-turns out I ended up participating in an intra college web design competition. Although I didn't cut short, it was a good experience. Fast-forward to today, I enjoy creating things for the web and have a keen eye for creating engaging UI, bringing products to life. Considering work as an ongoing education, I'm looking for opportunities where I can contribute, grow and bring out the best in me.
                      <br/>When not in front of my computer screen, I'm probably found exploring food hotspots, travelling, exploring wilderness or finding peace in mountains in my hometown or just trying to engage myself in any sort of physical activities.<br/>
                      <span>Feel free to get in touch with me over <a href="https://www.linkedin.com/in/ashish-pradhan-358291102/" target="_blank">Linkedin</a> or through the <a href="http://localhost:3000/#contact">Contact</a> section below or over an <a href="mailto:ashish.pradhan0696@gmail.com">e-mail</a>.</span>
                  </div>
                   <div className="info-right">
                    <label className='info-right_header'>Things that I've learned or worked with</label>
                     <div className="skills" style={{color : darkMode && "black"}}>                        
                        {skills.map((skill)=>(
                             <span className='tags'>{skill}</span>
                        ))}   
                     </div>
                     
                  </div>
            </div>
        </div>
    </>
  )
}
